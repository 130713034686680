<template>
    <div class="recuire-proccess-box">
        <!-- 头部标题和描述文案 -->
        <!-- <div class="recruitment-process-top">
            11111111
        </div> -->
        <!-- <div class="recruitment-process-top">
            <h5 class="recruitment-process-title">{{data.moduleFlowContent}}</h5>
            <p class="summer-desc">{{data.moduleSynopsis}}</p>
        </div> -->
        <!-- <img style="margin-top:50px;width:100%;" :src='require("@/assets/img/school/img.png")' alt=""> -->
        <div class="recruitment-process-bottom" v-if="data">
            <h5 class="process-title">{{data.moduleFlowContent}}</h5>
            <Process :data="data.tbModuleFlowModels"></Process>
        </div>
        <div class="icon-text">
            <p>*不同岗位推进节奏根据业务需求有所不同，请关注通知信息</p>
            <p>*岗位将动态更新，关注「蔚来招聘」公众号了解最新校招动态</p>
        </div>
    </div>
</template>
<script>
import { Process } from '@/components/index'
export default {
    name: 'RecruitmentProcess',
    components: {
        Process
    },
    props: {
        data: {
            type: Object,
            default: null,
            require: true
        }
    }
}
</script>
<style lang="less" scoped>
.recruitment-process-top {
    padding-top: 60px;
    .recruitment-process-title {
        padding-bottom: 16px;
        font-size: 24px;
        font-weight: bold;
        color: #333333;
    }
    .summer-desc {
        padding-bottom: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
    }
}
.recruitment-process-bottom {
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 80px;
    width: 996px;
    .process-title {
        padding-bottom: 32px;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #333333;
    }
}

.icon-text {
    text-align: center;
    padding-bottom: 30px;
    p{
        color: rgb(4, 11, 41);
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 10px;
    }
}
</style>
<template>
  <div class="recruitment-container-box" :class="recruitmentbox">
    <!-- 导航栏部分 -->
    <div v-if="active" class="recruitment-container">
      <el-tabs v-model="activeNavTab" @tab-click="handleClick">
        <el-tab-pane name="0">
          <div
            slot="label"
            class="tab-label flex-center"
            :class="{ active: activeNavTab == 0 }"
          >
            <i class="tab-icon flow mr5"></i>
            <span>招聘公告</span>
          </div>
          <Notice @showActive="showactive" :active="active"></Notice>
        </el-tab-pane>
        <el-tab-pane name="1">
          <div
            slot="label"
            class="tab-label flex-center"
            :class="{ active: activeNavTab == 1 }"
          >
            <i class="tab-icon qa mr5"></i>
            <span>答疑专区</span>
          </div>
          <QuestionAndAnswer
            :activeNavTab="activeNavTab"
          ></QuestionAndAnswer>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div v-else>
      <Notice @showActive="showactive" :active="active"></Notice>
    </div>
  </div>
</template>
<script>
import Notice from "./Notice.vue";
import QuestionAndAnswer from "./QuestionAndAnswer.vue";

export default {
  name: "Trends",
  components: { QuestionAndAnswer, Notice },
  data() {
    return {
      activeNavTab: "0",
      active: true,
      recruitmentbox: "recruitmentbox",
    };
  },
  created() {
    if (this.$route.query.active) {
      this.active = false;
    } else {
      this.active = true;
    }
  },
  methods: {
    showactive(data) {
      this.active = data;
    },
    handleClick(e) {
      this.activeNavTab = e.name;
      this.active = true;
    },
  },
};
</script>

<style lang="less" scoped>
.tab-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: cover;

  &.flow {
    background-image: url(~@/assets/img/icon-flow.png);
  }

  &.qa {
    background-image: url(~@/assets/img/icon-qa.png);
  }
}

.active {
  .tab-icon.flow {
    background-image: url(~@/assets/img/icon-flow-active.png);
  }

  .tab-icon.qa {
    background-image: url(~@/assets/img/icon-qa-active.png);
  }
}
.recruitment-container-box {
  width: 100%;
  min-height: calc(100% - 346px);
  background-color: #ecf7f7;

  .recruitment-container {
    .pr15 {
      padding-right: 15px;
    }

    .tab-label {
      color: rgba(147, 151, 153, 1);
      width: 144px;
    }

    .active {
      color: #1ebac1 !important;
    }

    /deep/ .el-tabs {
      font-weight: bold;
      .el-tabs__header {
        height: 60px;
        background-color: #fff;
        border-top: 1px solid #ced0d8;
        margin: 0px;
        // box-shadow: 1px #ccc;
      }

      .el-tabs__nav-wrap {
        margin: 0 auto;
        width: 550px;

        .el-tabs__nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
      }

      .el-tabs__nav-wrap::after {
        height: 0;
      }

      .el-tabs__active-bar {
        height: 2px;
        background-color: #00bebe;
      }

      .el-tabs__item {
        z-index: 1;
        font-size: 16px;
        text-align: center;
        color: #fff;
        width: 330px;
        padding: 0 69px;
        height: 60px;
        line-height: 60px;

        &.is-active {
          color: #1ebac1 !important;
        }
      }
    }
  }
}
</style>

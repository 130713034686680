<template>
  <div class="page pr">
    <div class="q-a pr">
      <div class="anchorMain">
        <ul class="anchorContent">
          <li
            class="anchorList"
            v-for="item in dataSource"
            :key="item.id"
            :class="{ clickStyle: item.color == 1 }"
            @click="goAnchor(item)"
          >
            <div>{{ item.typeName }}</div>
          </li>
        </ul>
      </div>
      <h5 class="q-a-title">{{ title }}</h5>
      <!-- <p class="q-a-desc">{{ title.desc }}</p> -->

      <div class="qa-list-wrap pr">
        <div
          v-for="item in dataSource"
          :key="item.id"
          ref="anchorDiv"
          style="background-color: #fafafa"
        >
          <ul class="q-a-content">
            <p class="title">{{ item.typeName }}：</p>
            <li class="q-a-list" v-for="(i, index) in item.list" :key="index">
              <div class="q-desc">
                {{ i.question }}
              </div>
              <div class="a-desc" v-html="i.answer"></div>
            </li>
          </ul>
        </div>
        <div class="lastInfo">
          <div class="q-a-list-info" v-html="footer"></div>
        </div>
        <i class="team-icon abs"></i>
      </div>
    </div>

    <div class="bg abs"></div>
  </div>
</template>
<script>
import { env } from "@/utils/getEnv.js";
import { questionAndAnswer } from "@/utils/config.js";
export default {
  name: "QuestionAndAnswer",
  props: {
    activeNavTab: {
      type: String,
      default: null,
      require: true,
    },
  },
  data() {
    return {
      title: "",
      dataSource: questionAndAnswer,
      footer: "",
    };
  },
  created() {
    fetch(
      `https://static.nio.com/fx-static/4349/fixed/qa.${
        env === "prod" ? "prod" : "test"
      }.json?t=${Date.now()}`
    )
      .then((r) => r.json())
      .then((res) => {
        this.title = res.title;
        this.footer = res.footer;
        this.dataSource = res.list?.map((item, index) => ({
          ...item,
          id: index + 1 + "",
        }));
      });
  },
  mounted: function () {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll, true);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    // 锚点跳转
    goAnchor(e) {
      this.dataSource.forEach((i) => {
        i.color = 0;
      });
      e.color = 1;
      let jump = this.$refs.anchorDiv;
      let total;
      for (let i = 0; i <= jump.length; i++) {
        if (i == e.id) {
          total = jump[e.id * 1 - 1].offsetTop + 5;
        }
      }
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 跳转step，step=10ms
      let step = total / 20;
      // 现坐标取整，如果坐标一致就不跳转
      distance = Math.ceil(distance);
      if (total > distance) {
        smoothDown();
      } else if (total == distance) {
      } else {
        let newTotal = distance - total;
        step = newTotal / 20;
        smoothUp();
      }
      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothDown, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothUp, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
    },
    //
    // 滚动锚点监听
    onScroll() {
      if (this.activeNavTab == 1) {
        // 　　   获取现在的位置
        let scrolled =
          document.documentElement.scrollTop || document.body.scrollTop;

        let jump = this.$refs.anchorDiv;
        scrolled = scrolled + 200;
        this.dataSource.forEach((item) => {
          item.color = 0;
          for (let i = 0; i < jump.length; i++) {
            item.color = 0;

            if (i + 1 >= jump.length) {
              if (jump[i].offsetTop <= scrolled && item.id == i + 1) {
                item.color = 1;

                break;
              }
            } else {
              if (
                jump[i].offsetTop <= scrolled &&
                scrolled <= jump[i + 1].offsetTop &&
                item.id == i + 1
              ) {
                item.color = 1;

                break;
              }
            }
          }
        });

        // 获取几个文章块的位置，同时监听现在的scroll位置，如果现在的scroll位置处于两个文章快的scrolltop距离之间，那就更改数据的color值。
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 80px;

  .bg {
    width: 100%;
    height: 694px;
    bottom: 0;
    left: 0;
  }

  @media screen and (min-width: 1301px) {
    .anchorMain {
      position: fixed;
      top: 272px;
      z-index: 1000;
      //background-color: (rgba(255, 255, 255, 0.5));

      .anchorContent {
        width: 204px;
        border-left: 1px solid #e3e5e8;
      }

      .anchorList {
        width: 100%;
        padding: 3px 0 3px 15px;
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 10px;
        color: #939799;
      }

      .anchorList.clickStyle {
        border-left: 2px solid #00bebe !important;
        color: #00bebe;
      }

      .anchorList:hover {
        color: #00bebe;
      }
    }

    //.clickStyle {
    //	border-left: 2px solid #00bebe !important;
    //	color: #00bebe;
    //}
  }
}

@media screen and (max-width: 1300px) {
  .anchorMain {
    display: none;
  }
}

.q-a {
  margin: 0 auto;
  padding-top: 60px;
  width: 1200px;
  z-index: 2;
  position: relative;

  .title {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    color: #040b29;
    margin: 32px 0 24px 0;
    margin-left: 37px;
  }

  .q-a-title {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #040b29;
    white-space: pre-wrap;
  }

  .q-a-desc {
    padding-bottom: 32px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #040b29;
    white-space: pre-wrap;
  }

  .qa-list-wrap.pr {
    width: 996px;
    margin-left: 204px;
    margin-top: 32px;
  }

  .lastInfo {
    margin-left: 40px;
    padding: 24px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(4, 11, 41, 1);
  }

  .q-a-content {
    width: 996px;
    margin: 25px auto;
    border-radius: 4px;
    padding: 8px 0;

    .q-a-list {
      margin: 0px 40px;
      padding: 24px 0;
      width: 920px;
      border-bottom: 1px dashed #ccc;
      background-color: #fafafa;

      .q-desc {
        // display: flex;
        line-height: 22px;
        padding-bottom: 12px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(4, 11, 41, 1);
        word-break: break-all;
      }

      .a-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        word-break: break-all;
        color: rgba(4, 11, 41, 0.5);
        text-align: justify;
      }

      .q-desc,
      .a-desc {
        padding-left: 24px;
        text-indent: -24px;
      }
    }

    .q-a-list:last-child {
      border-bottom: none;
    }
  }
}
</style>

<template>
  <div class="main-w notice" v-if="active">
    <div class="card">
      <p class="card-title">招聘公告</p>
      <div class="wrap">
        <ul class="flex-v">
          <li
            class="flex tagLi"
            v-for="(item, index) in config().recruitmentAnnouncement"
            :key="index"
            :class="{ isComing: !item.linkUrl }"
            style="cursor: pointer"
            @click="goDetails(item)"
          >
            <span class="wrapTitle"
              >{{ item.title }}
              <span
                v-for="(z, k) in item.label"
                :key="k"
                class="infoTag"
                :class="{
                  infoTagComing: !item.linkUrl,
                }"
                >{{ z }}</span
              ></span
            >
            <span
              style="
                color: rgba(4, 11, 41, 1);
                opacity: 0.5;
                font-size: 14px;
                font-weight: 400;
              "
              >{{ item.time }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <RecruitmentProcess
      class="card"
      v-if="active"
      :data="summerRecuritProcess"
    ></RecruitmentProcess>
    <!-- <div class="card">
      <p class="card-title">活动信息</p>
      <div class="wrap">
        <ul class="flex-v">
          <li class="flex tagLi isComing">
            <span class="wrapTitle noUrl">更多线上、线下活动敬请期待！</span>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
  <Detail v-else :demo="demo"></Detail>
</template>

<script>
import Detail from "./Detail.vue";
import RecruitmentProcess from "./RecruitmentProcess.vue";

export default {
  name: "notice",
  props: {
    active: {
      type: Boolean,
      default: null,
      require: true,
    },
  },
  inject: ["config"],
  components: {
    Detail,
    RecruitmentProcess,
  },
  data() {
    return {
      summerRecuritProcess: {
        id: 8,
        moduleFlowContent: "投递流程",
        tbModuleFlowModels: [
          {
            // flowName: "投递&内推",
            flowImage: require("@/assets/img/school/img-01.png"),
            flowRemark: "简历投递",
            flowIndex: 1,
            time: "",
          },
          {
            // flowName: "笔试/测评",
            flowImage: require("@/assets/img/school/img-02.png"),
            flowRemark: "简历评估",
            flowIndex: 2,
          },
          {
            // flowName: "面试",
            flowImage: require("@/assets/img/school/img-03.png"),
            flowRemark: "笔试/测评",
            flowIndex: 3,
            time: "",
          },
          // {
          //   // flowName: "意向书发放",
          //   flowImage:require("@/assets/img/school/img-04.png"),
          //   flowRemark: "实习评估 推荐校招",
          //   flowIndex: 4,
          // },
          {
            // flowName: "意向书发放",
            flowImage: require("@/assets/img/school/img-05.png"),
            flowRemark: "面试",
            flowIndex: 5,
            time: "",
          },
          {
            // flowName: "意向书发放",
            flowImage: require("@/assets/img/school/img-06.png"),
            flowRemark: "Offer",
            flowIndex: 6,
            time: "",
          },
        ],
      },
      demo: 1,
      dataId: null,
    };
  },
  created() {
    if (this.$route.query.demo) {
      this.demo = this.$route.query.demo;
    }
    if (this.$route.query.dataId) {
      this.dataId = this.$route.query.dataId;
    }
  },
  methods: {
    // 链接跳转
    goDetails(e) {
      if (e.linkUrl) {
        window.open(e.linkUrl, "_blank");
        // window.open(e.linkUrl, "_blank");
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.notice {
  width: 1036px;
  height: 100%;
}
.card-title {
  font-size: 24px;
  font-weight: 400;
  color: #040b29;
  margin-bottom: 32px;
  text-align: center;
}
.wrapTitle {
  font-size: 18px;
  font-weight: 400;
}
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  width: 100%;
  padding: 40px 44px;
  background: #fff;
  ul {
    grid-gap: 24px;
  }
  .tagLi {
    justify-content: space-between;
    align-items: center;
    color: #040b29;

    &:hover {
      span {
        color: #00b3be;
      }
    }
  }

  .infoTag {
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    margin-right: 10px;
    color: #00b3be;
    border: 1px solid rgba(0, 179, 190, 0.5);
    vertical-align: middle;
    font-size: 12px;
    line-height: 20px;
    padding: 0px 8px;
    border-radius: 2px;
    &:first-child {
      margin-left: 16px;
    }
  }

  .isComing {
    color: #939799;
    font-size: 18px;
    font-weight: 400;
    &:hover .wrapTitle {
      color: inherit;
    }
    .wrapTitle {
      cursor: auto;
    }
  }

  .infoTagComing {
    color: #ccc;
    border: 1px solid #ccc;
  }
}
.card {
  margin: 80px 0;
}
</style>
